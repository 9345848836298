import { Box, Button, Divider, Typography } from "@mui/material"
import { Form, Formik, FormikHelpers, useField, useFormikContext } from "formik"
import { FC, useEffect, useState } from "react"
import { BiCamera, BiNote } from "react-icons/bi"
import { useQuery } from "urql"
import * as Yup from "yup"
import {
  GetTasksForProjectReportingQuery,
  TaskReportInput,
  useCreateManyTaskReportsMutation,
} from "../graphql/generated/client-types-and-hooks"
import { graphql } from "../graphql/generated/gql"
import { useHandleError } from "../hooks/useHandleError"
import { FilterDropdownButton } from "./FilterDropdownButton"
import { TextField } from "./Formik/TextField"
import { ImageUploadPreviewList } from "./ImageUploadPreviewList"
import { errorSnack, successSnack } from "./Notistack/ThemedSnackbars"
import { DrawerFooter } from "./Partials/Drawer/DrawerFooter"
import { DrawerHeader } from "./Partials/Drawer/DrawerHeader"
import { UnitGoalRow } from "./UnitGoalRow"

export const testLabel_ReportUnitCard = "report-unit-card"

type Props = {
  projectId: string
  taskId?: string
  handleClose: () => void
}

const TasksQuery = graphql(`
  query GetTasksForProjectReporting($projectId: String!) {
    tasks(projectId: $projectId, status: active) {
      id
      name
      isDefault
      group {
        id
        name
      }
      unitGoals {
        id
        targetQuantity
        totalProgress
        isPrimary
        deliverableUnit {
          id
          description
          unitOfMeasure
        }
      }
    }
  }
`)

export const ProjectUnitReportFormContents: FC<Props> = ({ projectId, handleClose, taskId }) => {
  const { isSubmitting } = useFormikContext()

  const [{ data: taskData, fetching: tasksLoading, error: tasksError }, _refetchTasks] = useQuery({
    query: TasksQuery,
    variables: { projectId },
  })

  const tasks = (taskData?.tasks || []).filter(({ isDefault, unitGoals }) => !isDefault && unitGoals.length !== 0)
  const subtasks = tasks.filter((subtasks) => subtasks.group?.id === taskId)

  useHandleError(tasksError, "Could not fetch tasks")

  const [filteredTasks, setFilteredTasks] = useState<string[]>([])

  useEffect(() => {
    if (filteredTasks.length === 0) {
      Boolean(taskId)
        ? setFilteredTasks(subtasks.map((task) => task.id))
        : setFilteredTasks(tasks.map((task) => task.id))
    }
    // eslint-disable-next-line
  }, [tasksLoading])

  return (
    <Form>
      <div
        style={{
          height: "100vh",
          display: "grid",
          gridTemplateRows: "60px 1fr 80px",
        }}
      >
        {/* Header */}
        <DrawerHeader handleClose={handleClose} />

        {/* Body */}
        <div className="overflow-y-scroll p-7">
          <Typography variant="h4" fontSize="24" className="font-bold">
            Report Unit
          </Typography>
          <Divider className="my-6" />
          <FilterDropdownButton
            unfiltered={tasks}
            filtered={filteredTasks}
            setFiltered={setFilteredTasks}
            filterBy={(task: GetTasksForProjectReportingQuery["tasks"][0]) => task.id}
          />

          <div className="max-w-[466px] flex flex-col gap-4 pt-6">
            {tasks
              .filter(({ id }) => filteredTasks.includes(id))
              .map((task, i) => (
                <TaskReport key={task.id} task={task} taskIndex={i} />
              ))}
          </div>
        </div>

        {/* Footer */}
        <DrawerFooter>
          <div className="flex items-center gap-3 px-7">
            <Button type="submit" color="primary" size="large" variant="contained" disabled={isSubmitting}>
              Submit
            </Button>
            <Button variant="text" size="large" type="button" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </DrawerFooter>
      </div>
    </Form>
  )
}

const TaskReport: FC<{
  taskIndex: number
  task: GetTasksForProjectReportingQuery["tasks"][0]
}> = ({ taskIndex, task }) => {
  const [, , { setValue: setTaskId }] = useField(`taskReports[${taskIndex}].taskId`)
  const [showNoteField, setShowNoteField] = useState(false)
  const [showPhotoField, setShowPhotoField] = useState(false)

  const unitGoals = (task.unitGoals || []).sort((a, b) => {
    if (a.isPrimary && !b.isPrimary) return -1
    if (!a.isPrimary && b.isPrimary) return 1
    return 0
  })

  return (
    <div className="border border-gray-200 rounded-lg p-6" key={task.id} test-label={testLabel_ReportUnitCard}>
      <Typography variant="subtitle2" className="font-bold uppercase mb-10" color="primary">
        {task.group && <span className="text-gray-400">{task.group.name} / </span>}
        {task.name}
      </Typography>

      {unitGoals.map((unitGoal, unitGoalIndex) => (
        <UnitGoalRow
          key={unitGoal.id}
          unitGoalFieldName={`taskReports[${taskIndex}].unitGoals[${unitGoalIndex}].unitGoalId`}
          progressFieldName={`taskReports[${taskIndex}].unitGoals[${unitGoalIndex}].progress`}
          onChange={() => setTaskId(task.id)}
          unitGoal={unitGoal}
        />
      ))}

      <Box sx={{ display: "flex", flexDirection: "row", gap: 1, marginBottom: 3 }}>
        <Button
          className="px-4"
          color="secondary"
          variant="contained"
          endIcon={<BiNote />}
          onClick={() => setShowNoteField(!showNoteField)}
        >
          Note
        </Button>
        <Button
          className="px-4"
          color="secondary"
          variant="contained"
          endIcon={<BiCamera />}
          onClick={() => setShowPhotoField(!showPhotoField)}
        >
          Photo
        </Button>
      </Box>

      {showNoteField && (
        <TextField
          multiline
          fullWidth
          label="Notes"
          name={`taskReports[${taskIndex}].note`}
          required={false}
          rows={2}
          onChange={() => setTaskId(task.id)}
        />
      )}

      {showPhotoField && (
        <ImageUploadPreviewList
          name={`taskReports[${taskIndex}].files`}
          disabled={false}
          prefix={`task/${task.id}/image`}
          onChange={() => setTaskId(task.id)}
        />
      )}
    </div>
  )
}

type FileObject = { uploaded: boolean; fileId: string }

type UnitGoalInput = { unitGoalId: string; progress: number }

type TaskReport = {
  taskId: string
  note?: string
  files?: FileObject[]
  unitGoals?: UnitGoalInput[]
}

type ProjectUnitReportValues = {
  projectId: string
  taskReports: TaskReport[]
}

export const ProjectUnitReportForm: FC<Props> = ({ projectId, taskId, handleClose }) => {
  const [_, createManyTaskReports] = useCreateManyTaskReportsMutation()

  const handleSubmit = (values: ProjectUnitReportValues, { setSubmitting }: FormikHelpers<ProjectUnitReportValues>) => {
    const data = {
      ...values,
      taskReports: values.taskReports
        .filter((e) => e) // This patches a bug where undefined is added to the array.
        .map(({ files, unitGoals, ...taskReport }) => {
          const item: TaskReportInput = {
            ...taskReport,
            ...(files ? { fileIds: files.map((file) => file.fileId) } : {}),
            ...(unitGoals ? { unitGoalProgress: unitGoals?.filter((e) => e) } : {}),
          }

          return item
        }),
    }

    createManyTaskReports(data).then((result) => {
      if (result.error) {
        errorSnack("There was a problem submitting the form")
        console.error(result.error)
      } else {
        successSnack("Reported Successfully")
        handleClose()
      }

      setSubmitting(false)
    })
  }

  return (
    <Formik<ProjectUnitReportValues>
      initialValues={{ projectId, taskReports: [] }}
      validationSchema={Yup.object().shape({
        projectId: Yup.string(),
        taskReports: Yup.array().of(
          Yup.object().shape({
            taskId: Yup.string(),
            note: Yup.string(),
            files: Yup.array(
              Yup.object().shape({
                uploaded: Yup.bool().required().oneOf([true], "Files must finish uploading"),
                fileId: Yup.string(),
              })
            ),
            unitGoals: Yup.array(
              Yup.object().shape({
                unitGoalId: Yup.string(),
                progress: Yup.string(),
              })
            ),
          })
        ),
      })}
      onSubmit={handleSubmit}
    >
      <ProjectUnitReportFormContents taskId={taskId} projectId={projectId} handleClose={handleClose} />
    </Formik>
  )
}
