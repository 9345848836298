import { ArrayHelpers, FieldArray, useField } from "formik"
import { FC } from "react"
import { BiTrash } from "react-icons/bi"
import { MetadataNote } from "../../types/MetadataNote"
import { AddButton } from "../AddButton"
import { FormRow } from "../FormRow"
import { StandardInput } from "./StandardInput"
import { StandardTextarea } from "./StandardTextarea"

type Props = {
  name: string
  placeholder?: string
  tooltipLabel?: string
  tooltipText?: string
  disabled?: boolean
  removeItem: () => void
}

const emptyNote = { label: "", content: "" }

export const MetadataNoteInput: FC<Props> = ({ name, tooltipLabel, tooltipText, removeItem, ...props }) => {
  const [field] = useField(name)

  const { label = "", content = "" } = field.value

  const labelName = `${name}.label`
  const contentName = `${name}.content`
  return (
    <div className="px-1 m-2 bg-yellow-100 shadow-md rounded-md w-full">
      <FormRow childrenContainerClassName="flex justify-between md:col-span-12" overrideStyling>
        <StandardInput
          label="Note Title"
          labelClassName="hidden md:visible"
          containerClassName="pt-2 w-full"
          inputClassName="border-none bg-yellow-100 focus:border-none py-0"
          type="text"
          value={label}
          placeholder="Note Title"
          name={labelName}
        />

        <BiTrash
          className="m-4 text-2xl self-baseline h-6 w-6 text-gray-400 hover:text-gray-600 hover:cursor-pointer"
          onClick={() => removeItem()}
        />
      </FormRow>
      <FormRow textLabelClassName="hidden" childrenContainerClassName="flex md:col-span-12">
        <StandardTextarea
          className="w-full"
          labelClassName="hidden"
          textareaClassName="border-none bg-yellow-100 resize-none"
          label="Description"
          name={contentName}
          value={content}
          rows={2}
          {...props}
        />
      </FormRow>
    </div>
  )
}

type MetadataNoteListInputProps = {
  values: MetadataNote[]
  name?: string
}

export const MetadataNoteListInput: FC<MetadataNoteListInputProps> = ({ name = "metadata", values }) => {
  return (
    <FieldArray
      name={name}
      render={(arrayHelpers: ArrayHelpers) => (
        <div className="w-1/2">
          {(values || []).map((_, index) => (
            <MetadataNoteInput
              key={index}
              name={`metadata.${index}`}
              placeholder="Description"
              removeItem={() => arrayHelpers.remove(index)}
            />
          ))}

          <AddButton label="Add custom note" onClick={() => arrayHelpers.push(emptyNote)} />
        </div>
      )}
    />
  )
}
