import { Button } from "@mui/material"
import { FC } from "react"
import { BiPlus } from "react-icons/bi"
import { classNames } from "../helpers/classNames"
import { colors } from "../helpers/colors"

type Props = {
  className?: string
  fullWidth?: boolean
  label: string
  onClick: () => void
}

export const AddButton: FC<Props> = ({ fullWidth = true, className, label, onClick }) => {
  return (
    <div className={classNames("text-center text-gray-400 font-light text-xs pb-4", className)}>
      <Button
        sx={{
          height: "32px",
          margin: "0.5rem",
          fontSize: "14px",
          color: colors.gray[500],
          width: "calc(100% - 1rem)",
        }}
        startIcon={<BiPlus />}
        color="secondary"
        variant="contained"
        fullWidth={fullWidth}
        onClick={onClick}
      >
        {label}
      </Button>
    </div>
  )
}
