import { FC } from "react"
import { IconType } from "react-icons/lib"

type Props = {
  icon: IconType
  label: string | number
}

export const IconLabel: FC<Props> = ({ icon: Icon, label }) => {
  return (
    <div className="flex w-full gap-2 items-center">
      <Icon className="flex-shrink-0 h-6 w-6 text-gray-800 bg-gray-200 rounded p-1" />
      <div className="truncate leading-tight">{label}</div>
    </div>
  )
}
