import { Button, Checkbox, Divider, ListItemText, MenuItem, Select } from "@mui/material"
import { Dispatch, FC, SetStateAction, useState } from "react"
import { BiFilterAlt } from "react-icons/bi"

export const FilterDropdownButton: FC<{
  unfiltered: any[]
  filtered: any[]
  setFiltered: Dispatch<SetStateAction<string[]>>
  filterBy: (item: any) => string
}> = ({ unfiltered = [], filtered = [], setFiltered, filterBy }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button
        className="px-4"
        id="taskFilterButton"
        variant="contained"
        color="secondary"
        endIcon={<BiFilterAlt />}
        onClick={() => setOpen(true)}
      >
        Filter by task
      </Button>
      <Select
        multiple
        hidden
        value={filtered}
        onChange={(e) => {
          if (Array.isArray(e.target.value) && !e.target.value.includes("SELECT_ALL")) {
            setFiltered(e.target.value)
          }
        }}
        open={open}
        onClose={() => setOpen(false)}
        MenuProps={{
          BackdropProps: { sx: { background: "transparent" } },
          anchorEl: document.getElementById("taskFilterButton"),
          style: { marginLeft: "80px" },
        }}
      >
        <MenuItem
          value={"SELECT_ALL"}
          onClick={() => {
            if (filtered.length === unfiltered.length) setFiltered([])
            else setFiltered(unfiltered.map(filterBy))
          }}
        >
          <Checkbox checked={filtered.length === unfiltered.length} />
          <ListItemText primary={"Select all"} />
        </MenuItem>
        <Divider />
        {unfiltered.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            <Checkbox checked={filtered.includes(id)} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </>
  )
}
