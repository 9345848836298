import {
  Avatar,
  Checkbox,
  Chip,
  MenuItem,
  Autocomplete as MuiAutoComplete,
  TextField as MuiTextField,
} from "@mui/material"
import { useField } from "formik"
import debounce from "lodash.debounce"
import { useCallback, useEffect, useState } from "react"
import { useQuery } from "urql"
import { UserSelectQuery } from "../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../graphql/generated/gql"
import { Project, Task, User } from "../../graphql/generated/gql/graphql"
import { uniqueBy } from "../../helpers/uniqueBy"
import { SelectableUser } from "../../types/User"
import { PickPlus } from "../../types/helpers"
import UserWithTaskRow from "./MultiSelect/Rows/UserWithTaskRow"

type MyUser = SelectableUser &
  PickPlus<User, "imageUrl"> & {
    task?: PickPlus<Task, "id" | "name">
    project?: PickPlus<Project, "id" | "name">
  }
type Option = {
  value: string
  label: string
  user: MyUser
}

type Props = {
  name: string
  label: string
  className?: string
  disabled?: boolean
  multiple?: boolean
  preselected?: MyUser[]
  onChangeNotify?: (val: string[]) => void
}

const sortOptionsByProjectName = (a: Option, b: Option) => {
  // Ensure that "Overhead" is always at the top of the list
  const aKey = (a.user.project?.name || "").toLowerCase()
  const bKey = (b.user.project?.name || "").toLowerCase()

  if (aKey === "overhead") return -1
  if (bKey === "overhead") return 1

  // Sort by project name, ascending
  if (aKey > bKey) return 1
  if (aKey < bKey) return -1
  return 0
}

const UserSelectDocument = graphql(`
  query UserSelect($filter: UserFilter!, $first: Int, $after: String) {
    users(filter: $filter, first: $first, after: $after, sort: { by: projectName, direction: asc }) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          currentProjectId
          currentTaskId
          firstName
          lastName
          jobTitle
          imageUrl
          projectId
          task {
            id
            name
          }
          project {
            id
            name
          }
        }
      }
    }
  }
`)

type UserNode = NonNullable<UserSelectQuery["users"]["edges"][0]>["node"]

export const UserSelect = ({
  name,
  label,
  className,
  disabled,
  multiple = false,
  onChangeNotify,
  preselected,
}: Props) => {
  const [searchText, setSearchText] = useState("")
  const [cursor, setCursor] = useState<string | null>(null)
  const [loadedUsers, setLoadedUsers] = useState<UserNode[]>([])

  const [{ data }] = useQuery({
    query: UserSelectDocument,
    variables: { filter: { searchText, archived: false }, first: 15, after: cursor },
  })

  const [selected, setSelected] = useState<Option[]>(
    (preselected || []).map((u) => ({ value: u.id, label: `${u.firstName} ${u.lastName}`, user: u }))
  )
  const [_, { touched, error }, { setValue }] = useField(name)
  const displayError = touched && error && !disabled

  useEffect(() => {
    setLoadedUsers((prev) => [...prev, ...(data?.users.edges || []).map((edge) => edge?.node || ({} as UserNode))])
  }, [data])

  useEffect(() => {
    if (multiple) setValue(selected.map((item) => item.value))
    else setValue(selected?.[0]?.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const preselectedOptions = (preselected || []).map((u) => ({
    value: u.id,
    label: `${u.firstName} ${u.lastName}`,
    user: u,
  }))
  const fetchedOptions = (loadedUsers || []).map((user) => ({
    value: user.id!,
    label: `${user.firstName} ${user.lastName}`,
    user,
  }))
  const options = uniqueBy([...preselectedOptions, ...fetchedOptions], "value").sort(sortOptionsByProjectName)

  const setSearchTextProperly = (searchText: string) => {
    setCursor(null)
    setLoadedUsers([])
    setSearchText(searchText)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebounce = useCallback(debounce(setSearchTextProperly, 500), [])

  // Implementation Docs for "search as you type"
  // https://mui.com/material-ui/react-autocomplete/#search-as-you-type
  return (
    <MuiAutoComplete
      ListboxProps={{
        // This implementation would be removed after support for this feature lands:
        // https://github.com/mui/material-ui/pull/35653
        onScroll: (e) => {
          const listboxNode = e.currentTarget
          // Give a little fudge for maths
          if (listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight < 10) {
            setCursor(data?.users.pageInfo.endCursor || null)
          }
        },
      }}
      multiple
      disableCloseOnSelect={multiple}
      className={className}
      value={selected}
      options={options}
      renderOption={(props, option, { selected }) => {
        return (
          <MenuItem {...props} key={option.user.id}>
            {multiple && <Checkbox checked={selected} />}
            <UserWithTaskRow key={option.user.id} user={option.user} />
          </MenuItem>
        )
      }}
      filterOptions={(x) => x.sort(sortOptionsByProjectName)}
      onChange={(_, values) => {
        if (!Array.isArray(values)) setSelected([])
        else if (multiple) {
          setSelected(values)
        } else {
          const singleSelect = []
          const selectedOption = values[values.length - 1]
          if (selectedOption) singleSelect.push(selectedOption)
          setSelected(singleSelect)
        }
        onChangeNotify?.(values)
      }}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          size="small"
          label={label}
          onChange={(e) => handleDebounce(e.target.value)}
          helperText={displayError ? error : <>&nbsp;</>}
          error={!!displayError}
          inputProps={{
            ...(params.inputProps || {}),
            style: { boxShadow: "none" },
          }}
          FormHelperTextProps={{ className: "my-0.5" }}
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            key={option.value}
            label={option.label}
            variant="outlined"
            avatar={<Avatar src={option.user.imageUrl!} />}
          />
        ))
      }
      isOptionEqualToValue={(option, value) => value?.value === option?.value}
      groupBy={(option) => option.user.project?.name || ""}
    />
  )
}
