import { Task } from "../graphql/generated/client-types-and-hooks"

export type ProgressSummary = {
  noHours: boolean | undefined
  targetHours: number
  completedHours: number
  targetUnits: number
  completedUnits: number
  completionPercentage: number
}

export type TaskProgressDataType = {
  isComplete?: Task["isComplete"]
  isDefault?: Task["isDefault"]
  estimatedHours?: number | null | undefined
  timeEntriesSumDurationInSeconds: number | null | undefined
  unitGoalsSumProgress: number | null | undefined
  unitGoalsSumTargetQuantity: number | null | undefined
}

export const defaultTotals = {
  targetHours: 0,
  completedHours: 0,
  targetUnits: 0,
  completedUnits: 0,
}

/**
 * Summarizes the progress from a list of tasks.
 *
 * @param tasks - An array of tasks with data of task progress.
 * @returns A summary of progress including target hours, completed hours, target units, completed units and completion percentage.
 */
export const summarizeProgressFromTaskList = (tasks: TaskProgressDataType[] | undefined): ProgressSummary => {
  let sumOfEstimatedHours = 0
  const noEstimatedHours =
    tasks?.some((task) => !task.isDefault && !task.estimatedHours) || tasks?.every((task) => task.isDefault)

  const { completedHours, completedUnits, targetHours, targetUnits } =
    tasks?.reduce((acc, task) => {
      const taskTargetHours = task?.estimatedHours || 0

      // we only add a task's estimated hours to the total if estimated units are provided,
      // calculating progress with only hours isn't sufficient to measure productivity.
      if (task.unitGoalsSumTargetQuantity) {
        sumOfEstimatedHours += taskTargetHours
      }
      const taskWorkedHours = +(task?.timeEntriesSumDurationInSeconds || 0) / 3600
      const taskTargetUnits = task.unitGoalsSumTargetQuantity || 0
      //We want a consider a completed task to also have its units completed, if it has units
      const taskCompletedUnits = task.isComplete ? taskTargetUnits : task.unitGoalsSumProgress || 0

      return {
        targetHours: acc.targetHours + taskTargetHours,
        completedHours: acc.completedHours + taskWorkedHours,
        targetUnits: acc.targetUnits + taskTargetUnits,
        completedUnits: acc.completedUnits + taskCompletedUnits,
      }
    }, defaultTotals) || defaultTotals

  // Calculate then sum the weighted completion % for each task
  let completionPercentage =
    tasks?.reduce((acc, task) => {
      const taskTargetHours = task?.estimatedHours || 0
      const taskTargetUnits = task?.unitGoalsSumTargetQuantity || 0
      if (!taskTargetUnits || !taskTargetHours) return acc

      const taskWeight = taskTargetHours / sumOfEstimatedHours
      //a user may have completed a task without logging all units, so we will make that assumption
      const unitCompletionPercentage = task.isComplete ? 1 : (task.unitGoalsSumProgress || 0) / taskTargetUnits

      return acc + unitCompletionPercentage * taskWeight
    }, 0) || 0

  return {
    targetHours,
    completedHours,
    targetUnits,
    completedUnits,
    completionPercentage,
    noHours: noEstimatedHours,
  }
}

export const getProjectProgressSummary = (tasks: TaskProgressDataType[] | undefined): ProgressSummary => {
  return summarizeProgressFromTaskList(tasks)
}
