import TextareaAutosize from "@mui/base/TextareaAutosize"
import { TextareaAutosizeProps } from "@mui/material"
import React, { FC, TextareaHTMLAttributes } from "react"
import { IconType } from "react-icons"
import { overrideTailwindClasses } from "tailwind-override"
import { classNames } from "../../helpers/classNames"

type Props = {
  Icon?: IconType
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  value: string
}

export const Textarea: FC<TextareaHTMLAttributes<HTMLTextAreaElement> & Props & TextareaAutosizeProps> = ({
  className = "",
  ...props
}) => {
  return (
    <div className="relative">
      <TextareaAutosize
        {...props}
        className={overrideTailwindClasses(
          classNames("w-full rounded  border-gray-400 pl-3 placeholder:text-gray-400", className)
        )}
        style={{ boxShadow: "none" }}
      />
    </div>
  )
}
