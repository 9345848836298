import { InputAdornment, Typography } from "@mui/material"
import { useField } from "formik"
import { FC, useContext } from "react"
import { GetTasksForProjectReportingQuery } from "../graphql/generated/client-types-and-hooks"
import { getFriendlyFloat } from "../helpers/getFriendlyFloat"
import { OrganizationSettingsContext } from "../providers/OrganizationSettingsProvider"
import { TextField } from "./Formik/TextField"

export const UnitGoalRow: FC<{
  unitGoalFieldName: string
  progressFieldName: string
  unitGoal: GetTasksForProjectReportingQuery["tasks"][0]["unitGoals"][0]
  onChange?: (id: string) => void
}> = ({ unitGoal, unitGoalFieldName, progressFieldName, onChange = () => null }) => {
  const { getUnitSymbol } = useContext(OrganizationSettingsContext)
  const [, , { setValue: setUnitGoalId }] = useField(unitGoalFieldName)
  const unitSymbol = getUnitSymbol(unitGoal.deliverableUnit.unitOfMeasure)
  return (
    <div key={unitGoal.id} className="grid grid-cols-12 items-center">
      <div className="col-span-7">
        <div className="flex gap-2 mb-5">
          <Typography>{unitGoal.deliverableUnit.description}</Typography>
          <Typography className="text-gray-400">
            {getFriendlyFloat(unitGoal.totalProgress)} / {getFriendlyFloat(unitGoal.targetQuantity ?? 0)} {unitSymbol}
          </Typography>
        </div>
      </div>

      <div className="col-span-5">
        <TextField
          fullWidth
          className="h-14"
          label="Amount"
          size="small"
          type="number"
          onChange={() => {
            setUnitGoalId(unitGoal.id)
            onChange(unitGoal.id)
          }}
          name={progressFieldName}
          inputProps={{ max: 9999999 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" className="flex items-center gap-1">
                <Typography variant="caption">{unitSymbol}</Typography>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  )
}
