export const pluralize = (term: string, count: number): string => {
  if (count === 1) return term

  switch (term) {
    case "was":
      return "were"
    case "is":
      return "are"
    default:
      // Do nothing if the last letter is "s".
      if (term.slice(-1) === "s") {
        return term
      }

      return term + "s"
  }
}
