import { differenceInDays, format, isAfter, isSameYear } from "date-fns"
import { FC, useMemo } from "react"
import { Maybe } from "../../graphql/generated/gql/graphql"
import { classNames } from "../../helpers/classNames"
import { getDateProgressPercentageToNow } from "../../helpers/getDateProgressPercentageToNow"
import { pluralize } from "../../helpers/pluralize"
import { LabelWithNoBar } from "./LabelWithNoBar"

type DateProgressBarProps = {
  start?: Maybe<string | Date>
  end?: Maybe<string | Date>
  isComplete?: boolean
  withIcon?: boolean
}

export const DateProgressBar: FC<DateProgressBarProps> = ({ start, end, isComplete }) => {
  if (typeof start === "string") start = new Date(start)
  if (typeof end === "string") end = new Date(end)

  const overDays = end ? differenceInDays(new Date(), end) : 0
  const barColor = useMemo(() => {
    if (isComplete) return "bg-green-600"

    if (overDays > 0) return "bg-orange-800"

    return "bg-gray-600"
  }, [isComplete, overDays])

  if (!start || !end) {
    return (
      <div className="flex items-center gap-4">
        <LabelWithNoBar>No set dates</LabelWithNoBar>
      </div>
    )
  }

  const percentageComplete = getDateProgressPercentageToNow(start, end)
  const projectHasStarted = isAfter(new Date(), start)
  const dateFormatted = isSameYear(start, end) ? "MMM d" : "MMM d, yyyy"

  return (
    <div className="flex items-center gap-4 text-[13px] rounded overflow-hidden">
      <div className={classNames("relative w-full h-6 rounded overflow-hidden", barColor)}>
        {projectHasStarted && overDays < 0 && (
          <div
            className={classNames(
              "absolute top-0 left-0 bottom-0 inset-0 rounded",
              !isComplete && "bg-gray-800",
              percentageComplete < 1 && "rounded-r-none"
            )}
            style={{
              width: `${percentageComplete * 100}%`,
            }}
          />
        )}
        <div
          className={classNames(
            "absolute inset-0 py-1 px-3 text-white flex items-center justify-end gap-4 rounded font-bold",
            "xl:justify-between"
          )}
        >
          <div className={classNames("uppercase hidden", "xl:block")}>{format(start, dateFormatted)}</div>
          <div className="uppercase flex gap-1">
            <span className={classNames("hidden", "xl:block")}>{format(end, dateFormatted)}</span>
            <span className="xl:hidden">{format(end, "MMM d")}</span>
            {overDays > 0 && !isComplete && (
              <span>
                (+{overDays} {pluralize("Day", overDays)})
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
