import { TextField } from "@mui/material"
import { DatePicker, DateValidationError } from "@mui/x-date-pickers"
import { PickerChangeHandlerContext } from "@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types"
import { isEqual } from "date-fns"
import { useField } from "formik"
import { ChangeEvent, FC, useEffect, useState } from "react"
import { useQuery } from "urql"
import { GetOrgScheduleForProjectQuery } from "../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../graphql/generated/gql"
import { useHandleError } from "../../hooks/useHandleError"
import { WorkDay } from "./WeekDayToggleButtons"
import { calculateEndDate, calculateNumberOfWorkingDays, getExpandedNonWorkDays } from "./helpers"

const GetOrgScheduleDocument = graphql(`
  query GetOrgScheduleForProject {
    scheduleTemplates(isDefault: true) {
      id
      isDefault
      workDays {
        label
        active
      }
      workHours {
        hours
        startTime
        endTime
      }
      nonWorkDays {
        id
        name
        dateRange
        active
      }
    }
  }
`)

type Schedule = GetOrgScheduleForProjectQuery["scheduleTemplates"][0]

const needsUpdate = (date1: Date | null, date2: Date | null) => {
  if (!date1 || !date2) {
    return true
  }

  return !isEqual(date1, date2)
}

export const WorkDaysAndDates: FC<{
  schedule?: Schedule
  disabled?: boolean
}> = ({ schedule, disabled = false }) => {
  const [{ data, error }] = useQuery({
    query: GetOrgScheduleDocument,
    pause: Boolean(schedule),
  })

  useHandleError(error, "There was a problem retrieving schedule templates. Please try again.")

  const schedules: Schedule[] = (data?.scheduleTemplates as Schedule[]) || []
  const orgSchedule: Schedule = schedule || schedules?.[0]

  const [{ value: startDateValue }, _startMeta, startHelpers] = useField("startDate")
  const [{ value: endDateValue }, _endMeta, endHelpers] = useField("endDate")
  const [{ value: workDaysValue }, _workDaysMeta, workDaysHelpers] = useField("workDays")

  const [prevWorkDays, setPrevWorkDays] = useState<WorkDay[]>([])
  const nonWorkingDays = getExpandedNonWorkDays(orgSchedule?.nonWorkDays)

  useEffect(() => {
    if (!orgSchedule?.workDays || orgSchedule?.workDays?.every((day) => !day.active)) {
      return
    }

    if (workDaysValue && startDateValue) {
      const newEndDate = calculateEndDate(workDaysValue, startDateValue, orgSchedule.workDays, nonWorkingDays)
      if (needsUpdate(newEndDate, endDateValue)) {
        endHelpers.setValue(newEndDate)
      }
    } else if (startDateValue && endDateValue) {
      const workingDays =
        calculateNumberOfWorkingDays(orgSchedule.workDays, startDateValue, endDateValue, nonWorkingDays) || null
      if (workingDays !== workDaysValue) {
        workDaysHelpers.setValue(workingDays)
      }
    }
  }, [workDaysValue, workDaysHelpers, startDateValue, endDateValue, endHelpers, nonWorkingDays, orgSchedule])

  useEffect(() => {
    if (
      orgSchedule?.workDays?.length === prevWorkDays.length &&
      prevWorkDays?.every((element, index) => element === orgSchedule?.workDays?.[index])
    ) {
      setPrevWorkDays(orgSchedule?.workDays)
    }
  }, [orgSchedule?.workDays, prevWorkDays])

  const handleWorkDaysChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value
    const number = Number(value)

    if (number < 0) {
      return
    }

    endHelpers.setValue(null)
    workDaysHelpers.setValue(value === "" ? null : number)
  }

  const handleStartDateChange = (
    value: Date | null,
    { validationError }: PickerChangeHandlerContext<DateValidationError>
  ) => {
    if (validationError || !value) {
      return
    }

    startHelpers.setValue(value)
  }

  const handleEndDateChange = (
    value: Date | null,
    { validationError }: PickerChangeHandlerContext<DateValidationError>
  ) => {
    if (validationError || !value) {
      return
    }

    endHelpers.setValue(value)

    if (startDateValue) {
      const workingDays =
        calculateNumberOfWorkingDays(orgSchedule?.workDays || [], startDateValue, value, nonWorkingDays) || null
      workDaysHelpers.setValue(workingDays)
    }
  }

  const isDisabledDate = (day: Date) => nonWorkingDays.some((d) => isEqual(day, d))

  return (
    <>
      <TextField
        className="flex-auto"
        name="workDays"
        type="number"
        label="Work days"
        value={workDaysValue}
        onChange={handleWorkDaysChange}
        InputLabelProps={{ shrink: workDaysValue !== null }}
        disabled={disabled}
      />
      <DatePicker
        className="flex-auto"
        label="Start Date"
        value={startDateValue}
        onChange={handleStartDateChange}
        shouldDisableDate={isDisabledDate}
        slotProps={{
          textField: {
            id: "start-date",
          },
        }}
        disabled={disabled}
      />
      <DatePicker
        className="flex-auto"
        label="End Date"
        value={endDateValue}
        onChange={handleEndDateChange}
        shouldDisableDate={isDisabledDate}
        slotProps={{
          textField: {
            id: "end-date",
          },
        }}
        disabled={disabled}
      />
    </>
  )
}
