import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { ListVisibilityFilter } from "../graphql/generated/client-types-and-hooks"
import { useFlags } from "../providers/DevelopmentFeatureFlagProvider"
import { usePermissions } from "../providers/PermissionsProvider/PermissionsProvider"
import { CheckedPermission } from "../types/Permission"
import { ScopeAll } from "../types/Scope"

export const useListVisibilityFilter = (
  perm: CheckedPermission
): [ListVisibilityFilter, Dispatch<SetStateAction<ListVisibilityFilter>>] => {
  const { getAllowedScopes } = usePermissions()
  const { flagIsEnabled } = useFlags()

  const hasFeature = flagIsEnabled("My <> Filters")

  const readScopes = getAllowedScopes(perm)
  const initialVisibilityFilter = readScopes.includes(ScopeAll) ? ListVisibilityFilter.All : ListVisibilityFilter.My

  const [visibilityFilter, setVisibilityFilter] = useState<ListVisibilityFilter>(
    hasFeature ? initialVisibilityFilter : ListVisibilityFilter.All
  )

  useEffect(() => {
    // We are deliberately making sure that the initial value is set even though there's some intrinsic
    // latencies involved in determining the initial state.
    if (initialVisibilityFilter !== visibilityFilter) setVisibilityFilter(initialVisibilityFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialVisibilityFilter])

  return [visibilityFilter, setVisibilityFilter]
}
