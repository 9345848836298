// Shamelessly adapted from https://stackoverflow.com/a/40808569

export const uniqueBy = <T>(array: T[], predicate: string | Function) => {
  const callback = typeof predicate === "function" ? predicate : (o: { [key: string]: any }) => o[predicate]

  return [
    ...array
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : callback(item)

        map.has(key) || map.set(key, item)

        return map
      }, new Map())
      .values(),
  ]
}
