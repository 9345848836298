import { FC } from "react"
import { BiImage } from "react-icons/bi"
import { Organization, Project } from "../graphql/generated/client-types-and-hooks"
import { classNames } from "../helpers/classNames"
import { PickPlus } from "../types/helpers"

type ProjectImageProps = {
  organization?: PickPlus<Organization, "id" | "imageUrl" | "name">
  project: PickPlus<Project, "id" | "isArchived" | "isComplete" | "imageUrl" | "name">
  height?: string
  width?: string
  padding?: string
}

export const ProjectImage: FC<ProjectImageProps> = ({
  organization,
  project,
  height = "h-full",
  width = "w-full",
  padding = "p-5",
}) => (
  <div className="relative">
    <div className={classNames("absolute inset-y-0 -inset-x-2 flex flex-col items-center justify-center", height)}>
      {project.isArchived && (
        <div className="w-full bg-orange-600 text-white uppercase text-xs text-center font-bold py-2 rounded-md -rotate-12 outline outline-[6px] outline-white">
          Archived
        </div>
      )}
      {!project.isArchived && project.isComplete && (
        <div className="w-full bg-green-600 text-white uppercase text-xs text-center font-bold py-2 rounded-md -rotate-12 outline outline-[6px] outline-white">
          Completed
        </div>
      )}
    </div>

    {project.imageUrl && (
      // eslint-disable-next-line @next/next/no-img-element
      <img
        alt={project.name || "Project"}
        className={classNames("rounded z-0 object-cover", width, height)}
        src={project.imageUrl}
      />
    )}
    {!project.imageUrl && !organization?.imageUrl && (
      <div className={classNames(`rounded bg-gray-100`, width, height, padding)}>
        <BiImage className="h-full w-full text-gray-300" />
      </div>
    )}
    {!project.imageUrl && organization?.imageUrl && (
      <div className={classNames(`rounded bg-gray-100`, width, height)}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={organization.imageUrl}
          alt={organization.name || "Organization"}
          className={classNames("rounded object-cover opacity-10 grayscale p-2", height, width)}
        />
      </div>
    )}
  </div>
)
